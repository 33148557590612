body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.search{
	margin-top: 1vw;
	display: flex;
}

.search input {
	padding: 0.5rem;
	font-size: 1.0rem;
	width: 100%;
}

.locate {
	position: absolute;
	right: 0.5rem;
	background: none;
	border: none;
	z-index: 10;
}

.contenedorBuscador{
	position: absolute;
	left: 55%;
	transform: translateX(-50%);
	z-index: 10;
	width: 100%;
	max-width: 400px;
}

@font-face {
	font-family: 'OpenSans-Light';
	src: local('OpenSans-Light'), url(./resources/fonts/OpenSans-Light.ttf) format('truetype');
}


@font-face {
	font-family: 'OpenSans-Regular';
	src: local('OpenSans-Regular'), url(./resources/fonts/OpenSans-Regular.ttf) format('truetype');
}