html {
  height: 1000px;
}

a {
  text-decoration: none!important;
}

.checkmark_circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
}

.checkmark {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #7ac142;
  stroke-miterlimit: 10;
  margin: 2% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
}

@media(max-width: 599px) {
  .checkmark {
    margin-top: 64px;
    width: 250px;
    height: 250px;
  }
}

.checkmark_check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

#footerLogo:hover {
  animation: animOpacity 1s forwards;

}

@keyframes animOpacity {
  from {
    opacity: 0.5;
    filter: grayscale(80%);
  }
  to {
    opacity: 1.0;
    filter: grayscale(0%);
  }
}
